mat-expansion-panel {
  &.section-stepper {
    background-color: transparent;
    padding: 0;
    box-shadow: unset !important;
    overflow: visible;

    &:not(only-of-type) {
      margin-top: 8px;
    }

    > * {
      padding: 0;
    }

    .step {
      width: 100%;
    }

    .mat-expansion-panel-header {
      all: unset;
    }

    mat-panel-description {
      margin: 0;
    }

    .mat-expansion-panel-body {
      padding-right: 0;
      padding-bottom: 0;
    }
  }
}
