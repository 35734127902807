.page__title {
  color: var(--primary);
  font-size: 28px !important;
  line-height: 34px !important;
  font-weight: 500 !important;
  margin-bottom: 25px !important;
}

.page__sub-title {
  font-size: 20px !important;
  line-height: 28px !important;
  margin-bottom: 25px !important;
}

.text__table-header {
  color: var(--table-header)
}

li {
  font-size: 18px;
  margin-top: 22px;
}
