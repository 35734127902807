mat-toolbar {
  height: 91px;

  &.mat-toolbar {
    padding: 0 var(--content-padding-x);

    &.color-dark {
      background: var(--dark);
      color: var(--light);

      mat-icon, button {
        background: var(--dark);
        color: var(--light);
      }
    }
  }
}


@media only screen and (max-width: 1200px) {
  mat-toolbar {
    &.mat-toolbar {
      padding: 0;
    }
  }
}
