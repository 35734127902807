/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

@import "styles/common/vars";
@import "styles/common/lists";
@import "styles/common/text";
@import "styles/common/form";
@import "styles/common/bg";

@import "styles/org-tree";

@import "styles/overrides/material/mat-button";
@import "styles/overrides/material/mat-expansion-panel";
@import "styles/overrides/material/mat-label";
@import "styles/overrides/material/mat-select";
@import "styles/overrides/material/mat-toolbar";
@import "styles/overrides/material/mat-tooltip";
@import "styles/overrides/material/mat-tab";

* {
  box-sizing: border-box;

  :not(mat-icon) {
    font-family: Montserrat, sans-serif !important;
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Montserrat, sans-serif !important;
  font-size: 14px;
  color: var(--body-text);
}

.page {
  display: flex;
  flex-direction: column;

  .btn-bar {
    margin-top: 50px;
  }
}

.spacer {
  flex: 1 1 auto;
}

.btn-bar {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.outlined-radio {
  width: 100%;
  padding: 20px;
  border: 1px solid #E3E5EC;
  border-radius: 3px;

  &:not(:first-of-type) {
    margin-top: 15px;
  }

  mat-radio-group {
    display: flex;
    justify-content: space-between;
  }

  .mat-radio-button {
    width: 100%;

    .mat-radio-label {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      padding-right: 1.5em;

      .mat-radio-label-content {
        width: 100%;
        white-space: normal;
      }
    }
  }
}

[role='subheading'] {
  font-size: .75rem;
  font-style: italic;
}

// ITI overrides
.iti__no-flag {
  .iti__selected-flag {
    background-color: inherit;

    .iti__flag {
      display: none;
    }
  }
}


// --- CUSTOM SELECTS ---

.mobile-select-input .mat-form-field-infix {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;

  .country-code-container {
    display: flex;
    align-items: center;
    position: relative;

    width: 100px;

    &-small {
      width: 80px;
      text-align: end;
    }

    img {
      position: absolute;
      width: 22px;
      height: 22px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .mat-select {
      .mat-select-value {
        text-align: right;
      }
    }
  }
}

.country-select {
  position: relative;

  .mat-form-field-infix {
    padding-left: calc(1em + 22px);
    display: flex;

    img {
      position: absolute;
      width: 22px;
      height: 22px;
      left: 0;
      top: 22px;
      transform: translateY(-50%);
    }
  }
}

.mat-select-panel-wrap {
  .mat-option {
    .mat-option-text {
      display: flex;
      align-items: center;

      .select-image {
        width: 22px;
        height: 22px;

        margin-right: 1em;
      }
    }
  }
}

.radio-button-error {
  font-size: 75%;
  padding: 0 1em;
}
