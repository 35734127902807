mat-tab-group {
    counter-reset: tab-counter;
  .mat-tab-labels {
    .mat-tab-label-content {
      counter-increment: tab-counter;
      position: relative;
      font-size: 10px;
      &:before {
        content: counter(tab-counter);
        position: absolute;
        width: 1ch;
        height: 1em;
        font-size: 18px;
        left: -2ch;
      }
    }
  }
}
