.pre-line {
  white-space: pre-line;
}

.tooltip-danger {
  background: #F3D5DE;
  border: 2px solid #DB1D5D;
  color: #DB1D5D !important;
  font-size: 12px;
}
