.tree {
  font-size: 16px;
  overflow: auto;
}

.tree {
  &.invert {
    transform: rotate(180deg);
  }
}

.tree {
  &.invert {
    .node {
      transform: rotate(180deg);
    }
  }
}

.tree * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.tree ul {
  display: inline-flex;
}

.tree li {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
}

.tree li ul {
  margin: 2em 0;
}

.tree li li:before {
  border-top: 0.0625em solid var(--dark);
  content: "";
  display: block;
  height: 0.0625em;
  left: -0.03125em;
  position: absolute;
  top: -1.03125em;
  width: 100%;
}

.tree li li:first-child:before {
  left: calc(50% - 0.03125em);
  max-width: calc(50% + 0.0625em);
}

.tree li li:last-child:before {
  left: auto;
  max-width: calc(50% + 0.0625em);
  right: calc(50% - 0.03125em);
}

.tree li li:only-child:before {
  display: none;
}

.tree li li:only-child > .node-container:before,
.tree li li:only-child > .node-content:before {
  height: 1.0625em;
  top: -1.0625em;
}

.tree .node-container,
.tree .node-content {
  border: 0.0625em solid var(--dark);
  display: inline-block;
  margin: 0 0.5em;
  position: relative;
}

.tree .node-container:before,
.tree .node-content:before {
  top: -1.03125em;
}

.tree .node-container:after,
.tree .node-container:before,
.tree .node-content:after,
.tree .node-content:before {
  border-left: 0.0625em solid var(--dark);
  content: "";
  display: block;
  height: 1em;
  left: calc(50% - 0.03125em);
  position: absolute;
  width: 0.0625em;
}

.tree .node-container:after,
.tree .node-content:after {
  top: calc(100% + 0.03125em);
}

.tree .node-container:only-child:after,
.tree .node-content:only-child:after,
.tree > ul > li > .node-container:before,
.tree > ul > li > .node-content:before {
  display: none;
}

.tree.gap-sm li {
  padding: 0 0.6em;
}

.tree.gap-sm li > .node-container:before,
.tree.gap-sm li > .node-content:before {
  height: 0.6em;
  top: -0.6em;
}

.tree.gap-sm li > .node-container:after,
.tree.gap-sm li > .node-content:after {
  height: 0.6em;
}

.tree.gap-sm li ul {
  margin: 1.2em 0;
}

.tree.gap-sm li li:before {
  top: -0.63125em;
}

.tree.gap-sm li li:only-child > .node-container:before,
.tree.gap-sm li li:only-child > .node-content:before {
  height: 0.6625em;
  top: -0.6625em;
}

.tree.gap-lg li {
  padding: 0 1.5em;
}

.tree.gap-lg li > .node-container:before,
.tree.gap-lg li > .node-content:before {
  height: 1.5em;
  top: -1.5em;
}

.tree.gap-lg li > .node-container:after,
.tree.gap-lg li > .node-content:after {
  height: 1.5em;
}

.tree.gap-lg li ul {
  margin: 3em 0;
}

.tree.gap-lg li li:before {
  top: -1.53125em;
}

.tree.gap-lg li li:only-child > .node-container:before,
.tree.gap-lg li li:only-child > .node-content:before {
  height: 1.5625em;
  top: -1.5625em;
}

.tree li.dotted-children .node-container:after,
.tree li.dotted-children .node-container:before,
.tree li.dotted-children .node-content:after,
.tree li.dotted-children .node-content:before {
  border-left-style: dotted;
}

.tree li.dotted-children li:before {
  border-top-style: dotted;
}

.tree li.dotted-children > .node-container:before,
.tree li.dotted-children > .node-content:before {
  border-left-style: solid;
}

.tree li.dashed-children .node-container:after,
.tree li.dashed-children .node-container:before,
.tree li.dashed-children .node-content:after,
.tree li.dashed-children .node-content:before {
  border-left-style: dashed;
}

.tree li.dashed-children li:before {
  border-top-style: dashed;
}

.tree li.dashed-children > .node-container:before,
.tree li.dashed-children > .node-content:before {
  border-left-style: solid;
}

// ---- CUSTOM OVERRIDES ----
.tree {
  color: var(--black);
  border-color: var(--primary) !important;
  font-family: "Montserrat", sans-serif;
  text-align: center;

  li {
    &::after,
    &::before {
      border-color: var(--primary) !important;
    }

    .node-container {
      &::after,
      &::before {
        border-color: var(--primary) !important;
      }
    }
  }
}
