.lbl-stacked {
  display: flex;
  flex-direction: column;

  .mat-radio-group {
    padding-bottom: 1.5rem;
  }
}

.file-input-wrapper {
  position: relative;

  width: 100%;
  height: 100%;

  .mat-input-element {
    padding-right: 7%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .uploadFile {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;
    z-index: 0;

    cursor: pointer;
    pointer-events: none;
  }

  .file-icon {
    position: absolute;
    right: 0;
    top: 50%;

    transform: translateY(-50%);

    &.delete {
      cursor: pointer;
      z-index: 2;
    }
  }
}
