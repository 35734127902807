// SCSS Vars
// Theme Colors
$primary: #0060F5;
$success: #44D7B6;
$success-light: #e2f7f0;
$danger: #F83D62;
$danger-light: #f4d7df;
$light: #FCFCFC;
$dark: #000D22;

$body-text: #505257;
$footer-text: #959699;
$stepper-bg: #F4F6F9;
$stepper-bg-active: #EAECEF;
$stepper-text: #9E9FAA;
$stepper-text-active: #50566A;
$table-header: #636C9B;

$theme-colors: (
        "primary": $primary,
        "success": $success,
        "danger": $danger,
        "light": $light,
        "dark": $dark
);

// Spacing
$content-padding-x: 140px;
$content-padding-y: 100px;


// CSS vars
:root {
  // Theme Colors
  --primary: #0060F5;
  --success: #44D7B6;
  --success-light: #e2f7f0;
  --danger: #F83D62;
  --danger-light: #f4d7df;
  --light: #FCFCFC;
  --dark: #000D22;

  --body-text: #505257;
  --footer-text: #959699;
  --stepper-bg: #F4F6F9;
  --stepper-bg-active: #EAECEF;
  --stepper-text: #9E9FAA;
  --stepper-text-active: #50566A;
  --table-header: #636C9B;
  --form-label: #636C9B;

  // Spacing
  --content-padding-x: 140px;
  --content-padding-y: 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  // Spacing
  $content-padding-x: 50px;
  $content-padding-y: 50px;

  :root {
    // Spacing
    --content-padding-x: 50px;
    --content-padding-y: 50px;
  }

}
