.mat-select-panel {
  .select-controls {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--stepper-bg);
  }

  .mat-form-field, .mat-form-field-infix {
    flex: 1;
    margin-left: 5px;
  }
  input {
    &.select-input {
      flex: 1;
      //height: 35px;
      //background: var(--light);
      //padding: 5px 20px;
      //margin-top: 5px;
      //margin-bottom: 5px;
      //margin-right: 10px;
    }
  }
}
