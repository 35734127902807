ol {
  &.bullet-primary {
    counter-reset: li-counter;
    list-style: none;

    li {
      counter-increment: li-counter;
      position: relative;

      &:before {
        content: counter(li-counter);
        position: absolute;
        left: calc(-1 * 25px - 10px);

        display: flex;
        align-items: center;
        justify-content: center;

        width: 25px;
        height: 25px;
        font-size: 18px;
        text-align: center;

        color: var(--light);
        background: var(--primary);

        border-radius: 50%;
      }
    }
  }
}

li {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  margin-top: 22px;

  &.iti__country {
    margin-top: 12px;
  }
}
