.bg-danger {
  background: var(--danger-light);
  color: var(--danger);
  font-weight: 500;
}

.bg-success {
  background: var(--success-light);
  color: var(--success);
  font-weight: 500;
}

.bg-primary {
  background: var(--primary);
  color: var(--light);
}

.bg-dark {
  background: var(--dark);
  color: var(--light);
}

.bg-light {
  background: var(--light);
  color: var(--dark);
}
