button {
  &.bcb-btn {
    width: 200px;
    height: 50px;
    font-size: 19px;

    &.btn-thin {
      width: 120px;
      height: 50px;
      font-size: 15px;
    }

    &.btn-small {
      width: auto;
      height: fit-content;
      font-size: inherit;
    }

    &.btn-tiny {
      width: auto;
      height: fit-content;
      font-size: 12px;
      line-height: inherit;
      padding: 2px 15px;
    }

    &:not([disabled]) {

      &.primary {
        &.mat-flat-button {
          color: var(--light);
          background-color: var(--primary);
        }

        &.mat-stroked-button {
          color: var(--primary);
          background-color: var(--light);
          border-color: var(--primary);

          > .mat-button-focus-overlay {
            background: var(--primary);
          }
        }
      }

      &.danger {
        &.mat-flat-button {
          background-color: var(--danger);
          color: var(--light);
        }

        &.mat-stroked-button {
          background-color: var(--light);
          color: var(--danger);
          border-color: var(--danger);

          > .mat-button-focus-overlay {
            background: var(--danger);
          }
        }
      }
    }
  }

  &.mat-fab {
    &.btn-outline-primary {
      border-color: var(--primary);
      color: var(--primary);
      background: var(--light);
    }

    &.extended-fab {
      width: fit-content !important;
      padding: 0 20px 0 16px;
      border-radius: 56px;

      mat-icon {
        margin-right: 12px;
      }
    }
  }

}
